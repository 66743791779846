@font-face {
  font-family: 'REM';
  src: url('../assets/REM/static/REM-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'REM';
  src: url('../assets/REM/static/REM-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

body {
  font-family: 'REM', sans-serif;
}

#noticias {
  background-color: #DFEFA6;
}

.bg-white {
  background-color: white;
}

.rounded-2xl {
  border-radius: 1rem;
}

.shadow-md {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.overflow-hidden {
  overflow: hidden;
}

.w-full {
  width: 100%;
}

.max-w-4xl {
  max-width: 64rem;
}

.max-w-custom {
  max-width: 80rem;
}
.m-4 {
  margin: 1rem;
}

.transform {
  transition: transform 0.3s;
}

.transition-transform {
  transition: transform 0.3s;
}

.duration-300 {
  transition-duration: 300ms;
}

.hover\:scale-105:hover {
  transform: scale(1.05);
}

.p-6 {
  padding: 1.5rem;
}

.h-full {
  height: 100%;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.justify-between {
  justify-content: space-between;
}

.text-lg {
  font-size: 1.125rem;
}

.font-semibold {
  font-weight: 600;
}

.text-black {
  color: black;
}

.text-base {
  font-size: 1rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.text-sm {
  font-size: 0.875rem;
}

.font-normal {
  font-weight: 400;
}

.text-gray-500 {
  color: #6B7280;
}

.text-xs {
  font-size: 0.75rem;
}

.flex.justify-between.items-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.link-custom {
  color: black;
  font-size: 1.25rem;
  text-decoration: none;
  transition: all 0.3s ease;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: bold;
}

.link-custom:hover {
  font-weight: medium;
  text-decoration: underline;
  font-family: 'Josefin Sans', sans-serif;
  
}

.icon-custom {
  font-size: 0.875rem; 
  margin-left: 0.5rem; 
}



