@font-face {
  font-family: 'REM';
  src: url('../assets/REM/static/REM-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'REM';
  src: url('../assets/REM/static/REM-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

body {
  font-family: 'REM', sans-serif;
}

.noticia-detalhada-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10%;
  min-height: fit-content;
  background-color: #DFEFA6;
}

.noticia-detalhada-content {
  background-color: white;
  width: 80%;
  max-width: 65%;
  padding: 5vh 2vw;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: fit-content;
  position: relative;
}

.noticia-detalhada-header {
  display: flex;
  align-items: center;
  margin-left: 7%;
  margin-right: 10%;
  position: relative;
}

.back-arrow {
  cursor: pointer;
  font-size: 300%; 
  color: #000000; 
  position: absolute;
  left: -2%; 
  transition: color 0.3s ease;
}

.back-arrow:hover {
  color: #000000; 
}

.noticia-detalhada-content h1 {
  margin-top: 10%;
  margin-bottom: 4vh;
  font-size: 2.7rem;
  font-weight: 600;
  margin-left: 15%;
  margin-right: 10%;
  color: #000000;
  font-family: 'REM', sans-serif;
  text-align: left;
  width: 100%;
}

.noticia-detalhada-content p {
  margin-bottom: 2vh;
  color: #050000;
  margin-left: 20%;
  margin-right: 20%;
  font-size: 1rem;
  font-family: 'REM', sans-serif;
}

.noticia-link {
  margin-bottom: 6vh; 
  color: #0094FF;
  margin-left: 20%;
  margin-right: 20%;
  font-size: 1rem;
  font-family: 'REM', sans-serif;
  text-decoration: underline;
}

.noticia-date {
  margin-bottom: 4vh; 
  margin-left: 20%;
  margin-right: 20%;
  color: #050000;
  font-size: 1rem;
  font-family: 'REM', sans-serif;
}

.noticia-author {
  margin-bottom: 4vh; 
  margin-left: 20%;
  font-weight: bold;
  margin-right: 20%;
  color: #050000;
  font-size: 1rem;
  font-family: 'REM', sans-serif;
}
.noticia-subtitle {
  margin-bottom: 4vh;
  font-size: 1.1rem;
  font-weight: 500;
  margin-left: 20%;
  margin-right: 20%;
  color: #000000;
  font-family: 'REM', sans-serif;
  text-align: left;
  width: 65%;
}
