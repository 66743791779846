@font-face {
  font-family: 'REM';
  src: url('../assets/REM/static/REM-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'REM';
  src: url('../assets/REM/static/REM-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

body {
  font-family: 'REM', sans-serif;
}
.menu-noticias {
  background-color: #DFEFA6;
  padding-top: 10%;
}

.menu-noticias .w-full {
  display: flex;
  justify-content: space-between;
  
}

.menu-noticias img {
  padding-left: 8%;
  padding-top: 1%;
}

.menu-noticias .link-custom {
  margin-top: 1%;
  margin-right: 8%;
  text-decoration: none;
  color: black;
}

.news-item .noticia-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Número de linhas para exibir */
  -webkit-box-orient: vertical;
  line-clamp: 2; /* Adicionando a propriedade padrão */
  box-orient: vertical; /* Adicionando a propriedade padrão */
}