@font-face {
  font-family: 'REM';
  src: url('../assets/REM/static/REM-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'REM';
  src: url('../assets/REM/static/REM-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

body {
  font-family: 'REM', sans-serif;
}

.outer-container {
  background-color: #000000; /* Ensure this matches the intended background color */
}

.menu-artigos {
  background-color: #000000; /* Ensure this matches the intended background color */
  padding-top: 10%;
}

.menu-artigos .w-full {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; /* Align items to the top */
}

.logo-container {
  margin-top: 20px; /* Adjust margin to position the logo lower */
}

.menu-artigos img {
  padding-left: 24px;
  max-width: 200px; /* Adjust size if necessary */
  height: auto; /* Maintain aspect ratio */
}

.menu-artigos .link-custom {
  margin-top: 0; /* Remove top margin */
  margin-right: 24px;
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center; /* Align text and icon vertically */
  position: absolute;
  top: 20px; /* Position link at the top */
  right: 24px; /* Align link to the extreme right */
}

.menu-artigos .icon-custom {
  margin-left: 8px; /* Space between text and icon */
}

.artigo-item .artigo-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Número de linhas para exibir */
  -webkit-box-orient: vertical;
  line-clamp: 2; /* Adicionando a propriedade padrão */
  box-orient: vertical; /* Adicionando a propriedade padrão */
}
