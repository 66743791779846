@font-face {
  font-family: 'REM';
  src: url('../assets/REM/static/REM-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'REM';
  src: url('../assets/REM/static/REM-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

body {
  font-family: 'REM', sans-serif;
}
#artigos {
    background-color: black;
    color: white;
  }
  
  #artigos .link-custom {
    text-decoration: none;
    color: white;
  }
  
  #artigos .link-custom:hover {
    color: #DFEFA6;
  }
  